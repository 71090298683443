import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CompaniesAPIService } from 'src/app/services/API/Company/company.service';
import { UserAPIService } from 'src/app/services/API/UsersApi/userApi.service';
import { take } from 'rxjs/operators';
import { StorageServiceService } from 'src/app/services/API/storageServices/storage-service.service';

@Component({
  selector: 'app-worktogether-add-product-modal',
  templateUrl: './worktogether-add-product-modal.page.html',
  styleUrls: ['./worktogether-add-product-modal.page.scss'],
})
export class WorktogetherAddProductModalPage implements OnInit {
  newProductName: string = '';
  isContentLoaded: boolean = true;
  @ViewChild('productInput', { static: false }) productInput: ElementRef<HTMLElement>;

  constructor(private modalCtr: ModalController, private userAPIService: UserAPIService,private companiesAPIService: CompaniesAPIService, private storageService: StorageServiceService,) { }

  ngOnInit() {
    this.setAutoFocus()
  }

  setAutoFocus(){
    console.log(this.isContentLoaded);

  }

  addNewProductName() {
    this.storageService.get("userData").then((user)=>{
      if(user['companyID']!=""){
        const newProduct = {
          product: this.newProductName,
        };
        //this.userAPIService.addProduct(newProduct);
        this.userAPIService.getUserCurrentById().pipe(take(1)).subscribe((user)=>{

        this.companiesAPIService.addProduct(user.companyID, newProduct)

        });
        this.newProductName = '';
        this.modalCtr.dismiss();


    }else{
      this.closeModal();
    }
    });
  }

  async closeModal(){
    await this.modalCtr.dismiss();
  }

  isNewProductWritten() {
    return !(this.newProductName.length > 0);
  }

}
