import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/API/AuthenticationApi/authenticationApi.service';
import { Userservice } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.page.html',
  styleUrls: ['./change-email.page.scss'],
})
export class ChangeEmailPage implements OnInit {
  destroy$: Subject<void> = new Subject<void>();
  emailIsNotCorrect: boolean = false;
  loginForm: FormGroup;
  error_messages = {
    email: [
      { type: 'required', message: 'Email ist erforderlich.' },
      {
        type: 'pattern',
        message: 'Bitte geben Sie Ihre gültige Email-Adresse an.',
      },
    ],
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: Userservice,
    private authService: AuthenticationService,
    private modalCtl: ModalController
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.emailIsNotCorrect = false;
    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
     
    });
  }

  tryChangeEmail(value){
    this.emailIsNotCorrect = false;
    const email = value.email;

    
          this.authService.changeEmail(email);
          this.modalCtl.dismiss();
          this.authService.logoutUser();
       
  }
  

  cancelModal() {
    this.modalCtl.dismiss();
  }

}
