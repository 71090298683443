import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/API/AuthenticationApi/authenticationApi.service';
import { Userservice } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit {
  destroy$: Subject<void> = new Subject<void>();
  emailIsNotCorrect: boolean = false;
  oldPasswordIsWrong: boolean = false;
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private userService: Userservice,
    private authService: AuthenticationService,
    private modalCtl: ModalController
  ) {}


  error_messages = {
    email: [
      { type: 'required', message: 'Email ist erforderlich.' },
      {
        type: 'pattern',
        message: 'Bitte geben Sie Ihre gültige Email-Adresse an.',
      },
    ],

    // password: [
    //   { type: 'required', message: 'Passwort ist erforderlich.' },
    //   {
    //     type: 'minlength',
    //     message: 'Die Passwortlänge beträgt mindesttens 6 Zeichen.',
    //   },
    // ],
    // confirmpassword: [
    //   { type: 'required', message: 'Passwort ist erforderlich.' },
    //   {
    //     type: 'minlength',
    //     message: 'Die Passwortlänge beträgt mindesttens 6 Zeichen.',
    //   },
    // ],
  };
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.emailIsNotCorrect = false;
    this.oldPasswordIsWrong = false;

    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      //   oldPassword: new FormControl(
      //     '',
      //     Validators.compose([Validators.required, Validators.minLength(6)])
      //   ),
      //   password: new FormControl(
      //     '',
      //     Validators.compose([Validators.required, Validators.minLength(6)])
      //   ),

      //   confirmpassword: new FormControl(
      //     '',
      //     Validators.compose([Validators.required, Validators.minLength(6)])
      //   ),
      // },
      // {
      //   validators: this.password.bind(this),
    });
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  tryChangePassword(value) {
    this.emailIsNotCorrect = false;
    const email = value.email;
    // const oldPassword=value.oldPassword;
    // const newPassword=value.password;

    this.authService.changePassword(email);
    this.modalCtl.dismiss();

    // this.userService
    //   .getUserDataFromState()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((res) => {
    //     if (res['email'] == email) {
    //       this.authService.changePassword(email);
    //       this.modalCtl.dismiss();
    //     } else {
    //       this.emailIsNotCorrect = true;
    //     }
    //   });
  }

  cancelModal() {
    this.modalCtl.dismiss();
  }
}
